import React, { useState, useRef, useEffect, useCallback } from "react";
import { AudioVisualizer, LiveAudioVisualizer } from "react-audio-visualize";
import { useGetDevices, useGetMediaStream } from "../hooks";
import Button from "@mui/material/Button";

function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

function DeviceSelect({ handleChange }) {
  const [devices, getDevices] = useGetDevices();

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <div
      className="modal fade"
      id="audio-modal"
      tabIndex="-1"
      aria-labelledby="audio"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Select Audio Input
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <select className="form-select" onChange={handleChange}>
              {devices
                .filter((device) => device.kind === "audioinput")
                .map((device) => {
                  return (
                    <option key={device.deviceId} value={device.deviceId}>
                      {device.label}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Recorder() {
  const [blob, setBlob] = useState();
  const visualizerRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const recordedBlobs = useRef([]);
  const [recordStartTime, setRecordStartTime] = useState(null);
  const [recordEndTime, setRecordEndTime] = useState(null);
  const mediaRecorder = useRef(null);
  const audioRef = useRef(null);
  const audioContext = useRef(null);
  const analyser = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [devices, getDevices] = useGetDevices();
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  let [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    setSelectedDeviceId(e.target.value);
  };

  useEffect(() => {
    audioContext.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    analyser.current = audioContext.current.createAnalyser();
    analyser.current.fftSize = 2048; // You can adjust this value
    // getDevices();

    // Cleanup function
    return () => {
      if (audioContext.current && audioContext.current.state !== "closed") {
        audioContext.current.close();
      }
    };
  }, []);

  const uploadFile = useCallback(async () => {
    console.log(recordedBlobs);
    const audioBlob = new Blob(recordedBlobs.current, { type: "audio/mp4" });
    const file = new File([audioBlob], "audio.mp4", {
      type: "audio/mp4",
      lastModified: Date.now(),
    });

    let formData = new FormData();
    formData.append("recording[audio]", file);

    try {
      const response = await fetch("/api/v1/recordings", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        console.log("File uploaded successfully");
      }
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }, [recordedBlobs]);

  const onstop = useCallback((event) => {
    uploadFile();
    // setTimeout(uploadFile, 1000); // Adjust the delay as needed
    // console.log("data available after MediaRecorder.stop() called.");
    // const audioBlob = new Blob(recordedBlobs, { type: 'audio/mp4' });
    // setBlob(audioBlob);
    // const audioUrl = URL.createObjectURL(audioBlob);
    // audioRef.current.src = audioUrl;
    // setRecordedBlobs([]);
  }, []);

  const toggleRecording = (e) => {
    if (recording) {
      stopRecording(e);
    } else {
      startRecording(e);
    }
  };

  const startRecording = () => {
    const constraints = {
      audio: true,
      video: false,
    };

    if (selectedDeviceId) {
      constraints.audio = { deviceId: selectedDeviceId };
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const source = audioContext.current.createMediaStreamSource(stream);
        source.connect(analyser.current);

        try {
          if (isIOS()) {
            mediaRecorder.current = new MediaRecorder(stream, {
              mimeType: "audio/mp4",
            });
          } else {
            mediaRecorder.current = new MediaRecorder(stream, {
              mimeType: "audio/webm",
            });
          }
        } catch (error) {
          if (error.name === "NotSupportedError") {
            console.error("MIME type not supported:", error);
          } else {
            console.error("Unknown error occurred:", error);
          }
        }

        mediaRecorder.current.onstop = onstop;
        mediaRecorder.current.ondataavailable = ondataavailable;
        mediaRecorder.current.start();
        setRecording(true);
        setRecordStartTime(Date.now());
        // animateButtonShadow();
      })
      .catch((error) => {
        console.error("Error while starting recording:", error);
        if (error.name === "NotAllowedError") {
          console.error("Mic Permission denied");
          alert("Please allow access to your microphone and try again.");
        }
      });
  };

  const stopRecording = (e) => {
    e.preventDefault();
    console.log("Stop recording!");
    try {
      mediaRecorder.current.stop();
      setRecording(false);
    } catch (e) {
      console.log(e);
    } finally {
      setRecordEndTime(Date.now());
      console.log("totalduration", Date.now() - recordStartTime);
    }
  };

  const ondataavailable = (event) => {
    console.log("ondataavailable event:", event);
    if (event.data && event.data.size > 0) {
      console.log("data available", event.data.size, event.data.type);
      console.log("event.data", event.data);
      recordedBlobs.current = [...recordedBlobs.current, event.data];
    }
  };

  return (
    <div className="card mb-3 border-primary-subtle sticky-top">
      {mediaRecorder.current ? (
        <LiveAudioVisualizer
          className="live-audio-visualizer"
          mediaRecorder={mediaRecorder.current}
          width={"500px"}
          height={"75px"}
          barWidth={2}
          gap={2}
          backgroundColor="#f9731652"
          barColor={"#F97316"}
        />
      ) : (
        <div
          className="visualizer-placeholder mb-3"
          style={{
            aspectRatio: "auto 500/75",
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="mt-3 mb-3"
      >
        <button
          type="button"
          className="btn w-16 h-16 rounded-circle btn-outline-primary me-2"
          onClick={startRecording}
        >
          <i className="fas fa-pause"></i>
        </button>
        <div role="button" onClick={toggleRecording}>
          {recording ? (
            <img
              src="assets/mic_on.png"
              className="mic-animation"
              style={{ width: 80, height: 80 }}
            />
          ) : (
            <img src="assets/mic_off.png" style={{ width: 80, height: 80 }} />
          )}
        </div>
        <button
          type="button"
          className="btn w-16 h-16 rounded-circle btn-outline-primary ms-2"
          data-bs-toggle="modal"
          data-bs-target="#audio-modal"
          onClick={() => setModalOpen((current) => !current)}
        >
          <i className="fas fa-cog"></i>
        </button>
      </div>
      {/* <div className="btn-group" role="group" aria-label="Recording Controls">
      <button type="button" className="btn btn-outline-primary hover-white" onClick={startRecording}>
        <i className="fas fa-pause"></i>
      </button>
      <button type="button" className="btn btn-outline-primary recorder-button" onClick={startRecording}>
        <i className="fas fa-play"></i>
      </button>
      <button type="button" className="btn btn-outline-primary" onClick={stopRecording}>
        <i className="fas fa-stop"></i>
      </button>
      <button type="button" className="btn btn-outline-primary"
        data-bs-toggle="modal" data-bs-target="#audio-modal"
        onClick={() => setModalOpen((current) => !current)}>
        <i className="fas fa-cog"></i>
      </button>
    </div> */}
      <DeviceSelect handleChange={handleChange} />
    </div>
  );
}
