import { atom } from 'jotai'

export const recordingsAtom = atom(async (get, { signal }) => {
  const data = await fetch("/api/recordings", { signal })
  return data.json();
});

export const tagsAtom = atom(async (get, { signal }) => {
  const data = await fetch("/api/tags", { signal })
  return data.json();
});
