import { useState, useEffect } from 'react';

export const useGetDevices = () => {
  const [devices, setDevices] = useState([]);

  const getDevices = () => {
    (async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const deviceList = await navigator.mediaDevices.enumerateDevices();
        setDevices(deviceList);
        stream.getTracks().forEach((t) => t.stop());
      } catch (error) {
        console.error('Error while getting devices:', error);
      }
    })();
  };

  return [devices, getDevices];
};


export const useGetMediaStream = () => {
  const [mediaStream, setMediaStream] = useState();

  useEffect(() => {
    return () => {
      if (mediaStream === undefined) return;
      mediaStream.getTracks().forEach((t) => t.stop());
    };
  }, []);

  const getMediaStream = (device) => {
    if (device.kind === 'audiooutput') return;

    (async () => {
      const stream = await navigator.mediaDevices.getUserMedia(
        device.kind === 'audioinput'
          ? { video: false, audio: { deviceId: device.deviceId } }
          : { video: { deviceId: device.deviceId }, audio: false }
      );
      setMediaStream(stream);
    })();
  };

  return [mediaStream, getMediaStream];
};
