import ReactOnRails from 'react-on-rails';

import Time from '@/components/time';
import Recorder from '@/components/recorder';
import Recordings from '@/components/recordings';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  Time,
  Recorder,
  Recordings
});
