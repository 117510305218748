import { useAtom } from "jotai";
import React from "react";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";

import Typography from "@mui/material/Typography";

import { recordingsAtom, tagsAtom } from "../atoms/recording";
import { CardContent } from "@mui/material";

export default function Recordings() {
  const [recordings] = useAtom(recordingsAtom);
  const [tags] = useAtom(tagsAtom);

  console.log("TAGS", tags);
  console.log("Recordings", recordings);

  return (
    <div>
      <div className="mb-3">
        {tags.map((tag) => (
          <Chip
            key={tag.id}
            className="m-1"
            label={tag.name}
            onClick={() => console.log(tag.id)}
            variant="outlined"
          />
        ))}
      </div>
      {recordings.map((recording) => (
        <Card
          sx={{ marginBottom: "1em" }}
          key={recording.id}
          variant="outlined"
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {recording.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {recording.transcription}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
